import React, { useEffect, useState } from "react";
import { Divider, Empty, Row, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import TextArea from "antd/lib/input/TextArea";

import { useActions, useWindowSize, useTypedSelector } from "../../hooks";
import { ThemeColors, WindowWidth } from "../../consts";
import { IImage, IItem } from "../../models";
import { ConfirmDeleteItem, CreateEditItem, Spinner } from "..";

const { Text } = Typography;

interface ItemDataType extends Omit<IItem, "typeId"> {}
interface ImageDataType extends Omit<IImage, "itemId"> {}

export const AdminItemList = () => {
  const size = useWindowSize();
  const { fetchAdminLoadingItems, fetchAdminTotalCount } = useActions();

  const {
    item: { items, page, loading },
    type: { selectedType },
  } = useTypedSelector((state) => state);

  const [showCreateEditModal, setShowCreateEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [editedItem, setEditedItem] = useState<IItem>({} as IItem);

  const handleEdit = (record: ItemDataType) => {
    setEditedItem(record);
    setShowCreateEditModal(true);
  };

  const handleDelete = (record: ItemDataType) => {
    setEditedItem(record);
    setShowDeleteModal(true);
  };

  const getMasterColumns = (): ColumnsType<ItemDataType> => {
    return [
      {
        title: "Items",
        render: (record: ItemDataType) => (
          <>
            <Text strong style={{ color: ThemeColors.FontColor }}>
              id:
            </Text>
            &nbsp;{record.id}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              name:
            </Text>
            &nbsp;{record.name}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              number:
            </Text>
            &nbsp;{record.number}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              weight:
            </Text>
            &nbsp;{record.weight}
            <br />
            <Text strong style={{ color: ThemeColors.FontColor }}>
              price:
            </Text>
            &nbsp;{record.price}
            <br />
            <Space>
              <a onClick={() => handleEdit(record)}>Edit</a>
              <a onClick={() => handleDelete(record)}>Delete</a>
            </Space>
          </>
        ),
        responsive: ["xs"],
      },
      {
        title: "id",
        dataIndex: "id",
        key: "id",
        responsive: ["sm"],
      },
      {
        title: "name",
        dataIndex: "name",
        key: "name",
        responsive: ["sm"],
      },
      {
        title: "number",
        dataIndex: "number",
        key: "number",
        responsive: ["sm"],
      },
      {
        title: "weight",
        dataIndex: "weight",
        key: "weight",
        responsive: ["sm"],
      },
      {
        title: "price",
        dataIndex: "price",
        key: "price",
        responsive: ["sm"],
      },
      {
        key: "action",
        responsive: ["sm"],
        render: (record: ItemDataType) => (
          <Space>
            <a onClick={() => handleEdit(record)}>Edit</a>
            <a onClick={() => handleDelete(record)}>Delete</a>
          </Space>
        ),
      },
    ];
  };

  const getDetailsColumns = (): ColumnsType<ImageDataType> => {
    return [
      {
        title: "id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "file",
        dataIndex: "file",
        key: "file",
      },
    ];
  };

  const getMasterDataSource = (): ItemDataType[] => {
    return items
      .sort((a, b) => (a.id > b.id ? -1 : a.id < b.id ? 1 : 0))
      .map((item) => {
        const { id, name, price, number, weight, description, hasColor, images } = item;
        return { key: id, id, name, price, number, weight, description, hasColor, images };
      });
  };

  const getDetailsDataSource = (record: ItemDataType): ImageDataType[] => {
    return record.images
      .sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
      .map((image) => {
        const { id, file } = image;
        return { key: id, id, file };
      });
  };

  const detailsExpandable = {
    expandedRowRender: (record: ItemDataType) => (
      <>
        <Row style={{ backgroundColor: ThemeColors.Main }}>
          <TextArea
            rows={5}
            value={record.description}
            readOnly
            style={{
              width: size.width >= WindowWidth.Sx ? "40%" : "100%",
              color: ThemeColors.FontColor,
              backgroundColor: ThemeColors.Main,
            }}
          />
        </Row>
        <Divider />
        <Table
          style={{ width: size.width >= WindowWidth.Sx ? "40%" : "100%" }}
          size="small"
          dataSource={getDetailsDataSource(record)}
          columns={getDetailsColumns()}
          pagination={false}
        />
      </>
    ),
  };

  useEffect(() => {
    if (selectedType) {
      fetchAdminLoadingItems({ typeId: selectedType.id, page });
      fetchAdminTotalCount(selectedType.id);
    }
  }, [selectedType, page]);

  if (loading) {
    return <Spinner height={100} />;
  } else if (items.length === 0) {
    return <Empty description="Nothing here" style={{ color: ThemeColors.FontColor }} />;
  }

  return (
    <>
      <Table
        style={{ width: "100%" }}
        size="middle"
        expandable={detailsExpandable}
        dataSource={getMasterDataSource()}
        columns={getMasterColumns()}
        pagination={false}
      />
      <CreateEditItem
        show={showCreateEditModal}
        onShow={setShowCreateEditModal}
        editedItem={editedItem}
        setEditedItem={setEditedItem}
        admin={true}
      />
      <ConfirmDeleteItem
        show={showDeleteModal}
        onShow={setShowDeleteModal}
        editedItem={editedItem}
        setEditedItem={setEditedItem}
        admin={true}
      />
    </>
  );
};
