export const DEF_ITEM_ID = 0;
export const DEF_ITEM_NAME = "";
export const DEF_ITEM_PRICE = 0;
export const DEF_ITEM_NUMBER = 1;
export const DEF_ITEM_WEIGHT = 0;
export const DEF_ITEM_DESCRIPTION = "";
export const DEF_ITEM_HAS_COLOR = false;

export enum Urls {
  Api = "http://localhost:5000",
}

export enum TypeNames {
  Dolls = "Dolls",
  Outfits = "Outfits",
  Patterns = "Patterns",
  HeadsBodies = 'Heads & Bodies'
}

export enum LocalStorageNames {
  Token = "token",
  Type = "type",
  Cookie = "cookie",
}

export enum UserNames {
  Guest = "guest",
}

export enum UserRoles {
  Admin = "ADMIN",
  User = "USER",
}

export enum Keys {
  Enter = "Enter",
}

export enum PaymentType {
  PayPal = 1,
  Stripe = 2,
}

export enum ItemsPaginationSettings {
  Page = 1,
  Limit = 100,
  TotalCount = 0,
}

export enum AdminItemsPaginationSettings {
  Page = 1,
  Limit = 10,
  TotalCount = 0,
}

export enum OrdersPaginationSettings {
  Page = 1,
  Limit = 10,
  TotalCount = 0,
}

export enum ReviewsPaginationSettings {
  Page = 1,
  Limit = 4,
  TotalCount = 0,
}

export enum SubscribersPaginationSettings {
  Page = 1,
  Limit = 10,
  TotalCount = 0,
}

export enum Links {
  PrivacyPolicy = "https://www.privacypolicies.com/live/cc4e2396-4108-4152-b7a2-7815aeff984a",
  Facebook = "https://www.facebook.com/groups/elenapaclinadolls",
  Instagram = "https://www.instagram.com/elenapaclinadolls",
}

export enum WindowWidth {
  Sx = 576,
  Md = 768,
  Lg = 992,
  Xl = 1200,
  Xxl = 1600,
}

export enum SNColors {
  Google = "#df4930",
  Facebook = "#507cc0",
  Instagram = "#E948E3",
}

interface CCEntry {
  name: string;
  value: number;
}

export const ClothesColors: CCEntry[] = [
  { name: "Grey", value: 1 },
  { name: "White", value: 2 },
  { name: "Green", value: 3 },
  { name: "Yellow", value: 4 },
  { name: "Pink", value: 5 },
  { name: "Blue", value: 6 },
];

export enum Modes {
  Development = "development",
  Production = "production",
}

export enum DefCountry {
  USA = 40,
}

export enum Shipping {
  Error = -1,
  Within = 3,
  Takes = 10,
}

export enum ThemeColors {
  White = "#fff",

  Main = "#f2f2f2",
  Footer = "#fbd2c0",

  Primary = "#7a8dff",
  FontColor = "rgba(0,0,0,.85)",
  FontColorLight = "#939393",
  Shipping = "#8bdd8f63",
  PriceColor = "#d40000",
  Cart = "#ff5252",

  ErrorColor = "#ff4d4f",
  WarningColor = "#faad14",
  SuccessColor = "#52c41a",
  InfoColor = "#1890ff",
}
