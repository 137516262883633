import React, { useEffect, useMemo } from "react";
import { Row, Tabs, Typography } from "antd";

import { useActions } from "../hooks";
import { AdminItems, AdminOrders, AdminSubscribers, MainLayout } from "../components";
import {
  ItemsPaginationSettings,
  OrdersPaginationSettings,
  SubscribersPaginationSettings,
  ThemeColors,
} from "../consts";

const { Title } = Typography;

export const Admin = () => {
  const { setItemsPage, setOrdersPage, setSubscribersPage } = useActions();

  useEffect(() => {
    setItemsPage(ItemsPaginationSettings.Page);
    setOrdersPage(OrdersPaginationSettings.Page);
    setSubscribersPage(SubscribersPaginationSettings.Page);
  }, []);

  const adminTabs = useMemo(
    () => [
      { label: "Orders", key: "1", children: <AdminOrders /> },
      { label: "Items", key: "2", children: <AdminItems /> },      
      { label: "Subsribers", key: "3", children: <AdminSubscribers /> },
    ],
    []
  );

  return (
    <MainLayout>
      <Row justify="center">
        <Title level={1} className="ff-title" style={{ color: ThemeColors.FontColor }}>
          Admin
        </Title>
      </Row>
      <Tabs centered defaultActiveKey="1" items={adminTabs} style={{ color: ThemeColors.FontColor }} />
    </MainLayout>
  );
};
