import { useNavigate } from "react-router-dom";

import { TypeNames } from "../../../consts";
import { useActions, useTypedSelector } from "../../../hooks";
import { RouteNames } from "../../../routes";

export const BlockMenu = () => {
  const navigate = useNavigate();

  const { setSelectedType } = useActions();

  const {
    type: { types },
  } = useTypedSelector((state) => state);

  const handleGoToStore = (typeName: TypeNames) => {
    const type = types.find((type) => type.name === typeName);
    if (type) {
      setSelectedType(type);
      navigate(RouteNames.SHOP);
    }
  };

  return (
    <section className="u-clearfix u-section-2" id="sec-6805">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width u-gutter-0 u-layout-spacing-top u-layout-wrap u-layout-wrap-1">
          <div className="u-gutter-0 u-layout">
            <div className="u-layout-col">
              <div className="u-size-30">
                <div className="u-layout-row">
                  <div className="u-align-center u-container-style u-layout-cell u-left-cell u-palette-1-light-2 u-size-15 u-size-30-md u-layout-cell-1">
                    <div
                      className="u-container-layout u-valign-middle u-container-layout-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleGoToStore(TypeNames.Dolls)}
                    >
                      <h3 className="u-text u-text-1 dancing-script">Dolls</h3>
                      <div className="u-border-3 u-border-black u-line u-line-horizontal u-line-1"></div>
                    </div>
                  </div>
                  <div className="u-align-left u-container-style u-image u-layout-cell u-size-15 u-size-30-md u-image-1">
                    <div
                      className="u-container-layout u-container-layout-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleGoToStore(TypeNames.Dolls)}
                    ></div>
                  </div>
                  <div className="u-align-center u-black u-container-style u-layout-cell u-size-15 u-size-30-md u-layout-cell-3">
                    <div
                      className="u-container-layout u-valign-middle u-container-layout-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleGoToStore(TypeNames.Outfits)}
                    >
                      <h3 className="u-text u-text-3 u-text-color-3-light-3 dancing-script">Outfits</h3>
                      <div className="u-border-3 u-border-color-1-light-3 white u-line u-line-horizontal u-line-2"></div>
                    </div>
                  </div>
                  <div className="u-align-left u-container-style u-image u-layout-cell u-right-cell u-size-15 u-size-30-md u-image-2">
                    <div
                      className="u-container-layout u-container-layout-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleGoToStore(TypeNames.Outfits)}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="u-size-30">
                <div className="u-layout-row">
                  <div className="u-align-left u-container-style u-image u-layout-cell u-left-cell u-size-15 u-size-30-md u-image-3">
                    <div
                      className="u-container-layout u-valign-middle-xs"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleGoToStore(TypeNames.HeadsBodies)}
                    ></div>
                  </div>
                  <div className="u-align-center u-container-style u-layout-cell u-size-15 u-size-30-md u-white u-layout-cell-6">
                    <div
                      className="u-container-layout u-valign-middle u-container-layout-6"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleGoToStore(TypeNames.HeadsBodies)}
                    >
                      <h3 className="u-text u-text-5 dancing-script">Heads & Bodies</h3>
                      <div className="u-border-3 u-border-black u-line u-line-horizontal u-line-3"></div>
                    </div>
                  </div>
                  <div className="u-align-left u-container-style u-image u-layout-cell u-size-15 u-size-30-md u-image-4">
                    <div
                      className="u-container-layout"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleGoToStore(TypeNames.HeadsBodies)}
                    ></div>
                  </div>
                  <div className="u-align-center-lg u-align-center-sm u-align-center-xl u-align-center-xs u-container-style u-layout-cell u-palette-1-base u-right-cell u-size-15 u-size-30-md u-layout-cell-8">
                    <div
                      className="u-container-layout u-valign-middle u-container-layout-8"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleGoToStore(TypeNames.Patterns)}
                    >
                      <h3 className="u-align-center-md u-text u-text-7 dancing-script">Patterns</h3>
                      <div className="u-border-3 u-border-black u-line u-line-horizontal u-line-4"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
